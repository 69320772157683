import styled from "styled-components";

export const Container = styled.div`
  background:
    url(${(props) => props.$background}) repeat-x;
  position: relative;
  // background-size: 1920px 180px;
  background-size: 130%;
  background-position: right;
  text-align: center;
  color: hsl(0, 0%, 100%);
  max-width: 1920px;
  padding: 70px;
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
    background-size: cover;
    background-position: 35% top;
  }

  &.fixed {
    position: fixed;
    top: 80px;
    z-index: 2;
    @media (max-width: 1440px) {
      width: 100%;
    }
    @media (max-width: 768px) {
      top: 95px;
    }
    @media (max-width: 425px) {
      top: 90px;
    }
  }
`;

export const Centered = styled.div`
  position: absolute;
  font-size: 46px;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  //border-bottom: 4px solid;

  @media (max-width: 1440px) {
    font-size: 38px;
  }

  @media (max-width: 768px) {
    font-size: 34px;
  }

  @media (max-width: 425px) {
    font-size: 32px;
  }
`;

export const Image = styled.img`
  width: 100%;
`;

export const HR = styled.hr`
  opacity: 1;
  width: 90px;
  border: 2px solid;
  margin: auto;
`;
